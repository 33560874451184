import React, { Component } from 'react'
import { render } from 'react-dom';
import Title from '../components/Title';

export default class PagePricing extends Component {
    constructor(props) {
        super(props);
    }

  render() {
    const pageContent = this.props.pricing;
    return (
     <>

        <Title title="Pricing" />
        <section className="py-5">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 text-center ">
                        <h1 className="service_section_title">Pricing</h1>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-sm-12 mt-3' dangerouslySetInnerHTML={{ __html: pageContent }}>
                        
                    </div>
                </div>
            </div>
        </section>

    </>
    )
  }
}
