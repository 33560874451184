import React, { Component } from 'react'

export default class About extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <>
        <section className="about_section py-5">
          <div className="container">
            <div className="row">
              <div className="col-sm-6 text-center text-sm-start">
                <div className="about_content">
                  <h6> About me </h6>
                  <h1 className="wow animate__bounceIn">{this.props.settings.about_title}</h1>

                  <div className="about_description text-left py-2 wow animate__fadeInDown">
                    <p>
                      {this.props.settings.about_description}
                    </p>
                  </div>

                </div>
              </div>

              <div className="col-sm-6">
                <img src={this.props.settings.about_image} alt="" className="img-fluid wow animate__fadeInRight" />
              </div>
            </div>
          </div>
        </section>

      </>
    )
  }
}
