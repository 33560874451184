import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faCar } from '@fortawesome/free-solid-svg-icons';

export default class Service extends Component {
    constructor(props) {
        super(props);
      }



      
    //this method for render cashouts
    renderServices(){
        const services = this.props.services;
        return(
            <React.Fragment>
                <div className="row">
                
                    {services.map((service, index) => {
                     return(
                        <div className="col-sm-3 mt-4" key={index}>
                                <div className="single_service_block">
                                    <span className="">
                                        <a className={`wow animate__heartBeat c-`+service.color}   href="#">

                                            {service.image ? 

                                                <img src={service.image} className='img-fluid service_image' /> 
                                                :
                                                <FontAwesomeIcon icon={faCar} />
                                            }
                                            

                                        </a>
                                    </span>
                                    <h1>
                                        <a className={`c-`+service.color} href=""> {service.name}</a>
                                    </h1>
                                    <div className="text-justify service_descriptin"  dangerouslySetInnerHTML={{ __html: service.description }}>
                                    
                                    </div>
                                </div>
                            </div>
                     )
                    })}
                </div>
                
            </React.Fragment>
        );
    }


    render() {
        return (
            <>


                <section className="service_section text-center py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <h1 className="service_section_title">Our Service</h1>
                            </div>
                        </div>

                        {this.renderServices()}
                       
                    </div>
                </section>

            </>
        )
    }
}
