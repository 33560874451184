import React, { Component } from 'react'
import Title from '../components/Title'

export default class Feedback extends Component {
    constructor(props) {
        super(props);
      }



      
    //this method for render cashouts
    renderFeedbacks(){
        const feedbacks = this.props.feedbacks;
        return(
            <React.Fragment>
                <div className="row">
                
                    {feedbacks.map((feedback, index) => {
                     return(
                        <div className='col-sm-4' key={index}>
                                <div class="single_testimonial mt-3">
                                    <div class="testimonial_text text-start">
                                        <p>{feedback.feedback}</p>
                                    </div>
                                    <div class="testimonial_viewer">
                                        <div class="row">
                                            <div class="col-3">
                                                <img src={feedback.image} alt={feedback.description} class="img-fluid rounded-circle img-thumbnail" />
                                            </div>
                                            <div class="col-9">
                                                <h1 class="person_name">{feedback.name}</h1>
                                                <p class="person_address">{feedback.designation}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                     )
                    })}
                </div>
                
            </React.Fragment>
        );
    }
    render() {
        return (
            <>

                <Title title="Student Feed Back" />
                <section className="course_section py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 text-center">
                                <h1 className="service_section_title">Student Feedback</h1>
                            </div>
                        </div>

                        <div className='row'>
                            {this. renderFeedbacks()}
                        </div>
                    </div>
                </section>

            </>
        )
    }
}
