import React, { Component } from 'react'
import About from '../components/Home/About'
import Carosel from '../components/Home/Carosel'
import Course from '../components/Home/Course'
import Hero from '../components/Home/Hero'
import Service from '../components/Home/Service'
import Title from '../components/Title'

export default class Home extends Component {
  constructor(props) {
    super(props);
}
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <>

        <Title title="Home" />
        <Carosel  sliders={this.props.sliders}/>
        <About settings={this.props.settings} />
        {/* <Course courses={this.props.courses} /> */}
        <Hero settings={this.props.settings}  />
        <Service  services={this.props.services} />
      </>
    )
  }
}
